





































































































































import { Component, Vue } from 'vue-property-decorator';
import AccessMoreStrategiesFinancialHabitsViewModel
  from '@/vue-app/view-models/allianz-dashboard/access-more-strategies/access-more-strategies-financial-habits-view-model';

@Component({
  name: 'AccessMoreStrategiesFinancialHabits',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class AccessMoreStrategiesFinancialHabits extends Vue {
  habits_view_model = Vue.observable(
    new AccessMoreStrategiesFinancialHabitsViewModel(this),
  );

  created() {
    this.habits_view_model.initialize();
  }
}
